export const flightPlanUrl = '/flight-briefings/v1/flight-plans/';

export const searchUrl = `${flightPlanUrl}/searches`;

export const aircraftTypesUrl = '/flight-briefings/v1/aircraft-types';

export const airportsUrl = '/flight-briefings/v1/airports';

export const fuelOrderUrl = '/fuel-orders/v1';

export const weatherSearchUrl = '/flight-briefings/v1/weather/searches';

export const weatherReportUrl = '/flight-briefings/v1/weather-reports';

export const manualPlanUrl = '/flight-briefings/v1/bcp-flight-plans';

export const contactsUrl = '/flight-briefings/v1/contacts';

export const bannerUrl = '/flight-briefings/v1/banner-messages';
